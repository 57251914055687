@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* light dark theme color varient  */
@layer base {
  :root {
    --color-light-white: #ffffff;
    --color-dark-black: #000000;
    --color-search-input: #ffffff14;
    --color-gradient-purple-nl-purple: #9389df;
    --color-blur: #ffffff;
    --cs-success: #1baf4b;
    --cs-light-success: #d3ffe1;
    --cs-dark-light-purple: #9187de;
    --cs-radio-border: #6d6d9233;
    --cs-danger: #ff8b8b;
    --cs-table-heaer-bg: #f2f2ff;
    --cs-table-heaer-text: #4c4c4c;

    --color-dark: #161229;
    --color-dark-gray: #4c4c4c;
    --color-muted: #969696;
    --color-purple: #9187de;
    --color-body: #f7faff;
    --color-footer: #ffffff;
    --color-bd-light: #e3e2e9;
    --color-bd-dark-gray: #4c4c4c;
    --color-login-card: #ffffff;
    --color-login-form: #ffffff;
    --color-btn-gray: #ededed;
    --color-text-white: #4c4c4c;
    --color-text-xl-black: #000000;
    --color-stoke-slider-hover: #9389df;

    /* svg-stock */
    --color-search-stoke: #4c4c4c;
    --color-auth-icon-stoke: #4c4c4c;
    --color-black-white-icon-stoke: #161616;
  }

  html[data-theme="dark"] {
    --color-light-white: #000000;
    --color-dark-black: #ffffff;
    --color-search-input: #ffffff14;
    --color-gradient-purple-nl-purple: linear-gradient(
      181.01deg,
      #9187de 27.64%,
      #bbdefa 104.77%
    );
    --color-blur: #ffffff14;
    --cs-success: #1baf4b;
    --cs-light-success: #d3ffe1;
    --cs-dark-light-purple: #6052c9;
    --cs-danger: #ff8b8b;
    --cs-table-heaer-bg: #ffffff0d;
    --cs-table-heaer-text: #9389df;

    --color-dark: #f7faff;
    --color-dark-gray: #ededed;
    --color-muted: #969696;
    --color-purple: #9187de;
    --color-body: #161229;
    --color-footer: #262338;
    --color-bd-light: #6d6d9233;
    --color-bd-dark-gray: #e3e2e9;
    --color-login-card: #ffffff14;
    --color-login-form: #161229;
    --color-btn-gray: #ffffff24;
    --color-text-white: #ffffff;
    --color-text-xl-black: #ffffff;
    --color-stoke-slider-hover: #d2d6d9;

    /* svg-stock */
    --color-search-stoke: #9ca4b0;
    --color-auth-icon-stoke: #d2d6d9;
    --color-black-white-icon-stoke: #ffffff;
  }
}

[data-theme="dark"] .dark-img-hs,
[data-theme="light"] .light-img-hs {
}

[data-theme="dark"] .light-img-hs,
[data-theme="light"] .dark-img-hs {
  display: none !important;
}

/* font  */

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

body {
  @apply font-poppins bg-body m-0 min-h-screen h-full text-th-black;
}

::placeholder {
  @apply text-xs capitalize ;
}

.btn-dark {
  @apply bg-dark text-th-white px-4 rounded-lg text-base;
  /* padding: 7px 30px; */
}

.btn-outline-gradient {
  @apply bg-primary-gradient-color dark:bg-white text-base;
  padding: 1px;
}

.btn-outline-gradient p {
  @apply bg-white dark:bg-body dark:hover:bg-transparent dark:text-white  text-base hover:bg-transparent hover:text-white transition duration-200 ease-in-out h-full;
  /* padding: 6px 30px; */
}

.btn-blur {
  @apply bg-blur border border-bd-light;
}

.blur-filter {
  @apply bg-blur;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.footer-section {
  @apply bg-footer w-full py-10;
}

@media (max-width: 1023px) {
  .toggle-menu-navbar {
    @apply hidden;
  }

  .header-section.active .toggle-menu-navbar {
    @apply block;
    flex-basis: 100%;
    flex-grow: 1;
  }
}

.login-card {
  @apply border-2 mx-auto border-bd-light;
  max-width: 1250px;
  border-radius: 40px;
  background-color: #ffffff14;
  backdrop-filter: blur(10px);
}

.login-form {
  @apply bg-login-form sm:p-8 p-4;
  box-shadow: 0px 1px 6px 0px #00000040;
  border-radius: 40px;
}
.bg-overley {
  @apply bg-light-dashboard-chart dark:bg-dashboard-chart bg-no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}

.btn-gradient {
  @apply bg-primary-gradient-color text-white;
}

.btn-gray {
  @apply bg-btn-gray text-dark-gray;
}

.login-dotline-title {
  @apply bg-login-form;
}

.login-dotline-title:after {
  content: "";
  @apply absolute w-full start-0 end-0 top-1/2 border bg-bd-light;
}

.login-dotline-title p {
  @apply bg-login-form inline-block relative px-2 py-1;
  z-index: 2;
}

/* dark icon (S) */

.search_dark_icon svg path {
  stroke: var(--color-search-stoke);
}

.auth_dark_icon svg path {
  /* stroke: var(--color-search-stoke); */
  fill: var(--color-search-stoke);
}

.black_white_icon svg path {
  /* stroke: var(--color-search-stoke); */
  fill: var(--color-black-white-icon-stoke);
}

/* dark icon (S) */

.main-search {
  @apply bg-search-input border border-bd-light;
  backdrop-filter: blur(10px);
}

.bg-overley {
  @apply bg-light-dashboard-chart dark:bg-dashboard-chart bg-no-repeat w-full;
  background-position: center;
  background-size: cover;
}

.banner-section {
  padding: 90px 0px 185px 0px;
}
.badge-outline-blur {
  @apply bg-search-input border border-bd-light;
  backdrop-filter: blur(10px);
}

input:-webkit-autofill {
  @apply border-bd-light;
  border: 1px solid var(--color-bd-light);
  -webkit-text-fill-color: var(--color-dark-gray);
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.perpul-line::before {
  content: "";
  @apply absolute w-52 bottom-0;
  background: var(--color-gradient-purple-nl-purple);
  height: 2px;
}

.light-green-badge {
  @apply bg-light-success p-1 px-2 text-success rounded-full text-xs font-bold;
}

.filter-tab {
  @apply flex items-center bg-blur rounded-xl p-1 border border-bd-light;
}

.radio-filter {
  @apply inline-block;
}

.radio-filter input:checked + label {
  @apply bg-purple text-white;
}

.radio-filter label {
  @apply px-4 py-1.5 rounded-lg text-gray-400 font-normal text-xs block;
}

.cs-table tr th,
.cs-table tr td {
  @apply p-3 text-center;
}
.cs-table tr th {
  @apply text-purple text-base font-medium py-4;
}
.cs-table tr td {
  @apply text-sm font-normal;
}
.cs-table thead tr th {
  @apply bg-blur bg-table-heaer-b text-table-heaer-t;
}

/* .close-menu, */
.open-menu {
  position: absolute;
  cursor: pointer;
  display: none;
}

.open-menu {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.close-menu {
  top: 20px;
  right: 20px;
}

#check {
  display: none;
}

@media (max-width: 1023px) {
  .menu {
    @apply justify-center w-full h-auto right-0 align-middle flex-col items-center fixed z-50 text-white;
    top: -120%;
    background-color: var(--color-body);
    transition: all 0.5s ease-in-out;
  }
  .toggle-menu-navbar {
    @apply block;
  }

  .close-menu,
  .open-menu {
    @apply block;
  }

  #check:checked ~ .menu {
    @apply top-0;
    transition: top 0.5s ease-in-out;
  }
}

.bd-b-purple::before {
  content: "";
  @apply w-44 bg-purple h-1 block absolute;
  bottom: -2px;
}

.TickerSwiper .swiper-slide {
  @apply w-auto;
  margin-right: 20px;
}

.text-gradient {
  @apply font-semibold text-xl;
  background: var(--color-gradient-purple-nl-purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
}

.btn-swiper {
  @apply bg-bd-light rounded-full;
  padding: 2px;
}
.btn-swiper span {
  @apply rounded-full h-10 w-10 flex items-center justify-center bg-body;
}
.btn-swiper:hover {
  background-color: var(--color-stoke-slider-hover);
}

.btn-swiper:hover span svg path {
  fill: var(--color-stoke-slider-hover);
  fill-opacity: 1;
}

.btn-swiper span svg path {
  fill: var(--color-text-white);
}
li.active .navbar_url {
  color: var(--color-purple);
}
@media (min-width: 320px) and (max-width: 640px) {
  .container {
    max-width: 100%;
  }
}
.light-dark-themetoogle {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(181.01deg, #9187de 27.64%, #bbdefa 104.77%) border-box;
  border-radius: 8px;
  border: 1px solid transparent;
}

[data-theme="dark"] .light-dark-themetoogle {
  background: linear-gradient(black, black) padding-box,
    linear-gradient(181.01deg, #9187de 27.64%, #bbdefa 104.77%) border-box;
}

.profile-dropdown {
  background-color: var(--color-login-form);
}
.profile-dropdown .acive svg path {
  fill: var(--color-login-form);
  border-color: var(--color-bd-light);
}
.header-wrapper {
  z-index: 9999;
}
.header-wrapper.scrolled .navigation {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
  background-color: var(--color-blur);
}

@media (min-width: 1024px) {
  .header-wrapper.scrolled {
    @apply backdrop-blur-md;
    top: 10px;
  }
  .header-wrapper {
    transition: 0.5s;
  }
}
@media (max-width: 1023px) {
  .header-wrapper.scrolled {
    background-color: var(--color-login-form);
  }
  .header-wrapper.scrolled .navigation {
    box-shadow: unset;
    background-color: unset;
  }
  .header-wrapper .navigation {
    padding: 15px 0px;
  }
  .header-wrapper  {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
  }
}


.stocks-table-container {
  padding: 20px;
}

.search-bar {
  margin-bottom: 15px;
  padding: 8px;
  width: 100%;
  max-width: 300px;
}

.stocks-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.stocks-table th,
.stocks-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.stocks-table th {
  cursor: pointer;
  background-color: #f4f4f4;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
